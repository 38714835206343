<template>
    <div class="content-body">
        <div class="header">
            <el-button type="primary" @click="addHotKeywords"><i class="iconfont">&#xe609;</i>添加关键词</el-button>
        </div>
        <el-table class="content-table customTable" :data="hotKeywordslList" style="width: 100%; margin-top: 20px; flex: 1;" height="1%" size="medium"
                  :header-cell-style="{fontWeight: 'normal', height: '60px', color: '#666666', background: '#F6F6F6',fontSize: '16px'}"
                  :cell-style="{fontSize: '16px',color: '#333333',height: '70px'}">
            <el-table-column prop="name" align="left" label="关键词名称"></el-table-column>
            <el-table-column align="center" label="操作" width="150">
                <template slot-scope="scope">
                    <el-link type="success" :underline="false" @click="editHotKeywords(scope.row.id)">编辑</el-link>
                    <el-link type="danger" :underline="false" @click="delHotKeywords(scope.row.id)">删除</el-link>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination class="pager-center" style="margin: 20px 0;text-align: center"
                       :current-page="hotKeywordsPages.currentPageNum"
                       :page-size="hotKeywordsPages.eachPageNum"
                       :total="hotKeywordsPages.total"
                       layout="prev, pager, next, jumper"
                       @current-change="hotKeywordsCurrentChange">
        </el-pagination>
    </div>
</template>

<script>
    import {adminKeyWordListKeyWord, adminKeyWordDelKeyWord} from '@/utils/apis'
    export default {
        name: "HotKeywords",
        data() {
            return {
                //关键词列表
                hotKeywordslList: [],
                //分页
                hotKeywordsPages:{
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0,
                },
            }
        },
        mounted() {
            this.getHotKeywordsList();
        },
        methods: {
            //获取关键词列表
            getHotKeywordsList() {
                let param = {
                    type: 'page',
                    limit: this.hotKeywordsPages.eachPageNum,
                    page: this.hotKeywordsPages.currentPageNum
                }
                adminKeyWordListKeyWord(param).then((res) => {
                    this.hotKeywordslList = res.data.data;
                    this.hotKeywordsPages.total = res.data.total;
                }).catch((err) => {
                    console.log(err);
                });
            },
            //添加关键词
            addHotKeywords() {
                this.$router.push({
                    path: '/practice/hotKeywords/create'
                })
            },
            //编辑
            editHotKeywords(id) {
                this.$router.push({
                    path: '/practice/hotKeywords/create',
                    query: {
                        id: id
                    }
                })
            },
            //删除
            delHotKeywords(id) {
                this.$confirm(`是否删除该关键词，删除将无法恢复`, '删除', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    customClass:'green-message-box',
                    type:'warning'
                }).then(()=>{
                    let param = {
                        id: id
                    }
                    adminKeyWordDelKeyWord(param).then((res) => {
                        this.$message.success(res.msg);
                        this.getHotKeywordsList();
                    }).catch((err) => {
                        console.log(err);
                    });
                }).catch(()=>{
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                })
            },
            //分页
            hotKeywordsCurrentChange(val){
                this.hotKeywordsPages.currentPageNum = val;
                this.getHotKeywordsList();
            }
        }
    }
</script>

<style scoped lang="scss">
    .content-body {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        padding: 0 20px;
        box-sizing: border-box;
        .header {
            display: flex;
            justify-content: flex-end;
            i {
                font-size: 12px;
                margin-right: 5px;
            }
        }
        .content-table {
            width: 100%;
            margin-top: 20px;
            flex: 1;
        }
    }
</style>